.card {
  border: none;
  border-radius: 15px;
}
.adiv {
  background: #778a24;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 12px;
  height: 46px;
}
.card img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  opacity: 0.5;
}
.fas {
  cursor: pointer;
}
.fa-chevron-left {
  color: #fff;
}
h6 {
  font-size: 12px;
  font-weight: bold;
}
small {
  font-size: 10px;
  color: #898989;
}
.form-control {
  border: none;
  background: #f6f7fb;
  font-size: 12px;
  padding: 20px;
}
.form-control:focus {
  box-shadow: none;
  background: #f6f7fb;
}
.form-control::placeholder {
  font-size: 12px;
  color: #b8b9bd;
}
.btn-primary {
  background: #778a24;
  padding: 4px 0 7px;
  border: none;
}

.btn-primary:hover {
  background: #778a2490;
}
.btn-primary:focus {
  box-shadow: none;
}
.btn-primary span {
  font-size: 12px;
  color: #fff;
}
p.mt-3 {
  font-size: 11px;
  color: #b8b9bd;
  cursor: pointer;
}
