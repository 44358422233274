@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Jost:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Great+Vibes&display=swap");

body {
  overflow-x: hidden;
}
button,
h1,
h2,
h3,
h4,
h5.h6,
p,
span {
  font-family: "Jost", sans-serif !important;
}

p {
  font-size: 1.2rem !important;
}

a:hover {
  color: inherit !important;
  text-decoration: none !important;
}
.layoutMargin {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.caveatFont {
  font-family: "Caveat", cursive !important;
}

.logoHome {
  width: 140px;
}
.serviceCardImg {
  width: 500px;
  height: 500px;
}
.svgFooter svg {
  width: 30px;
  height: 30px;
}
.date-picker input {
  padding: 7px !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #778a24 !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  height: 300px !important;
}

.swiper-pagination-bullet {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin: 0 3px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #cea261;
  width: 1.875rem;
  height: 0.75rem;
  border-radius: 20px;
}

.swiper-button-next,
.swiper-button-prev {
  text-rendering: auto;
  color: #cea261 !important;
}
table {
  border-collapse: separate;
  border-spacing: 0px 4px;
}

@media screen and (max-width: 1340px) {
  .layoutMargin {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 768px) {
  .serviceCardImg {
    width: 300px;
    height: 300px;
  }
}
